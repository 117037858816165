
#world-map-markers{
    svg{
        g{
            g{
                path{
                    fill: #c9d6de;
                }
            }
        }

    }
}

.card-item
{
    display: flex;
    justify-content: space-between;
}

.bg-custom-blue
{
  background-color: #05294F;
  color:white;
}

.seccion-1
{
    width: 150px;
}