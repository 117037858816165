.color0
{
    background-color: white;
    width: 100px;
    height: 100px;
    color: black
}
.color1
{
    background-color: #05294F;
    width: 100px;
    height: 100px;
    color: white
}
.color2
{
    background-color: #004890;
    width: 100px;
    height: 100px;
    color: white
}
.color3
{
    background-color: #5B78AA;
    width: 100px;
    height: 100px;
    color: white
}
.color4
{
    background-color: #06ACCD;
    width: 100px;
    height: 100px;
    color: white
}
.color5
{
    background-color: #53B4C6;
    width: 100px;
    height: 100px;
    color: white
}
.color6
{
    background-color: #99CA3E;
    width: 100px;
    height: 100px;
    color: white
}
.color7
{
    background-color: #909090;
    width: 100px;
    height: 100px;
    color: white
}