.fecha-personalizada
{
    margin-left: 10px;
    outline: 1px solid rgb(144, 159, 167,.5);
    /* border:1px solid #909fa7 ; */
    height: 39px;
    color:#575962;    
}

input[type="date"].fecha-personalizada
{
    width: 140px;
}

#columna-fecha
{
    display: flex;
    justify-content: space-between;
}