@import url("https://cdnjs.cloudflare.com/ajax/libs/toastr.js/2.1.3/toastr.min.css");

//css
@import "~bootstrap/scss/bootstrap";


.main-content {
  padding: 15px 15px;
  padding-top: 0px;
  padding-bottom: 40px;
  margin-left: 240px !important;
  transition: all .5s ease;
  background-repeat: repeat;

  &.nav-collapsed {
    margin-left: 60px !important;
    height: 100%;
    transition: all .5s ease;
    background-repeat: repeat;
  }
}

.card-header {
  text-transform: none !important
}

.jodit-wysiwyg ul {
  margin-left: 7px;
  /* Espacio para el texto enriquecido, para mostrar las viñetas */
}



//@extend

// icon
@import "./assets/fontawesome-pro/css/all.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~simple-line-icons/css/simple-line-icons.css";
@import "~weather-icons/css/weather-icons.css";

// scss
@import "./assets/scss/style.scss";

///jvectormap
@import "./assets/css/jvectormap";
//ReactToastify
@import '~react-toastify/dist/ReactToastify.css';

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused) {
  background-color: #99ca3e75;
  /*#E8F8F5;*/
  color: #333;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr:last-child>td {
  border-bottom: 1px solid #99ca3e75;
}
