.degradado1
{
    background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(171,195,219,1) 100%);
    width: 100px;
    height: 100px;
    color: black
}
.degradado2
{
    background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(248,228,229,1) 100%);
    width: 100px;
    height: 100px;
    color: black
}
.degradado3
{
    background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(220,243,247,1) 100%);
    width: 100px;
    height: 100px;
    color: black
}
.degradado4
{
    background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(166,224,167,1) 100%);
    width: 100px;
    height: 100px;
    color: black
}